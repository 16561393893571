import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";

class OrganizationSshKeys extends React.Component {
  render() {
    return (
      <ContentLayout id="settings-members" className="settings">
        <Heading2>
          <FormattedMessage id="api_tokens" />
        </Heading2>
      </ContentLayout>
    );
  }
}

OrganizationSshKeys.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationSshKeys;
